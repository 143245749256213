import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: null,
    respPermissionData: null,
  },
  getters: {
  },
  mutations: {
    GET_PERMISSION(state, payload) {
      state.respPermissionData = []
      if (payload.length > 0) {
        for (let index = 0; index < payload.length; index += 1) {
          state.respPermissionData.push({
            module: payload[index].name,
            subject: payload[index].subject,
            read: false,
            update: false,
            create: false,
            delete: false,
          })
        }
      }
    },
    GET_PERMISSION_UPDATE(state, payload) {
      for (let indexPermisstion = 0; indexPermisstion < state.respPermissionData.length; indexPermisstion += 1) {
        console.log(state.respPermissionData[indexPermisstion])

        for (let index = 0; index < payload.length; index += 1) {
          if (state.respPermissionData[indexPermisstion].subject === payload[index].subject) {
            if (payload[index].action === 'read') {
              state.respPermissionData[indexPermisstion].read = true
            }
            if (payload[index].action === 'update') {
              state.respPermissionData[indexPermisstion].update = true
            }
            if (payload[index].action === 'create') {
              state.respPermissionData[indexPermisstion].create = true
            }
            if (payload[index].action === 'delete') {
              state.respPermissionData[indexPermisstion].delete = true
            }
          }
        }
      }
    },
    GET(state, payload) {
      state.respData = payload
    },
    ADD(state, payload) {
      state.respData.data.unshift(payload)
    },
    UPDATE(state, payload) {
      const productIndex = state.respData.data.findIndex(p => p._id === payload._id)
      Object.assign(state.respData.data[productIndex], payload)
    },
    REMOVE(state, id) {
      if (state.respData != null) {
        const ItemIndex = state.respData.data.findIndex(p => p._id === id)
        state.respData.data.splice(ItemIndex, 1)
        // eslint-disable-next-line operator-assignment
        state.respData.max = state.respData.max - 1
      }
    },
  },
  actions: {
    get({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/eCommerce/permissionInApp/get?pageSize=${item.pageSize}&currentPage=${item.currentPage}&searchQuery=${item.searchQuery}`)
          .then(response => {
            commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getPermission({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get('/api/web/application/eCommerce/permissionInApp/getPermission')
          .then(response => {
            commit('GET_PERMISSION', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    view({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/eCommerce/permissionInApp/view?id=${item}`)
          .then(response => {
            commit('GET_PERMISSION_UPDATE', response.data.data.permission)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    add({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/application/eCommerce/permissionInApp/add', item)
          .then(response => {
            commit('ADD', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    update({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/eCommerce/permissionInApp/update', item)
          .then(response => {
            commit('GET_PERMISSION_UPDATE', response.data.data.permission)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    delete({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/eCommerce/permissionInApp/delete', item)
          .then(response => {
            commit('REMOVE', item.id)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
